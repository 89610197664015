import { Box, Flex, Text } from '@chakra-ui/react';
import DirectusResponsiveImage from '@components/DirectusResponsiveImage';
import { PageContext } from '@components/Page';
import { useContext, useMemo } from 'react';
import { NikeActivityFinderAnswers } from 'src/queries/directus';
import * as styles from './Answers';
import dynamic from 'next/dynamic';
import MotionVStack from '@components/MotionVStack';
import { fadeIn, fadeUp, fadeUpWrapper } from '@styles/defaultAnims';
import MotionBox from '@components/MotionBox';
const DistanceAnimation = dynamic(
  () => import('@components/DistanceAnimation'),
  {
    ssr: false
  }
);

export enum AnswersVariant {
  REGULAR,
  REGULAR_SUBTITLE,
  SMALL,
  TIME,
  DISTANCE
}

type AnswerProps = {
  variant?: AnswersVariant;
  answer: NikeActivityFinderAnswers;
  selectAnswer: (answer: NikeActivityFinderAnswers) => void;
};

const Answer = ({ answer, variant, selectAnswer }: AnswerProps) => {
  const { copy } = useContext(PageContext);

  const title = useMemo(() => {
    const text = answer.translations[0].text;

    switch (variant) {
      case AnswersVariant.TIME: {
        const match = /([^\[\n]*)\s*\[\[([^\]]+)\]\]\s*([^\n]*)/.exec(text);
        return match?.[2];
      }
      case AnswersVariant.DISTANCE: {
        const match = /(\d{1,2})\s(KM)/gi;
        return text.replace(match, '$1\u00A0$2');
      }
      default:
        return text;
    }
  }, [answer, variant]);

  const subtitle = useMemo(() => {
    const text = answer.translations[0].text;

    switch (variant) {
      case AnswersVariant.REGULAR_SUBTITLE:
        const key = answer.name;
        if (key === 'Speed') return copy('quiz.run-type.speed');
        if (key === 'Endurance') return copy('quiz.run-type.endurance');
        return null;
      case AnswersVariant.TIME: {
        const match = /([^\[\n]*)\s*\[\[([^\]]+)\]\]\s*([^\n]*)/.exec(text);
        return match?.[3];
      }
      default:
        return null;
    }
  }, [answer, variant, copy]);

  const hasImage = useMemo(() => {
    return (
      variant !== AnswersVariant.TIME && variant !== AnswersVariant.DISTANCE
    );
  }, [variant]);

  return (
    <Box {...styles.item(variant)} onClick={() => selectAnswer(answer)}>
      {hasImage && (
        <DirectusResponsiveImage
          {...styles.itemImage}
          image={answer.image}
          imageMobile={answer.imageMobile}
        />
      )}
      <Box zIndex={2}>
        <Text {...styles.itemTitle(variant)}>{title}</Text>
        {subtitle && <Text {...styles.subTitle(variant)}>{subtitle}</Text>}
      </Box>
    </Box>
  );
};

type AnswersProps = {
  variant?: AnswersVariant;
  answers: NikeActivityFinderAnswers[];
  selectAnswer: (answer: NikeActivityFinderAnswers) => void;
};

export const Answers = ({
  variant = AnswersVariant.REGULAR,
  answers,
  selectAnswer
}: AnswersProps) => {
  const animation = useMemo(() => {
    return variant === AnswersVariant.DISTANCE ? fadeIn : fadeUp;
  }, [variant]);
  return (
    <MotionVStack
      w="full"
      key={answers.toString()}
      flexGrow="1"
      minHeight={0}
      {...fadeUpWrapper(0)}
    >
      <MotionBox {...animation} maxH="full">
        <Flex {...styles.container(variant)}>
          {variant === AnswersVariant.DISTANCE && (
            <Box {...styles.mapWrapper}>
              <Box {...styles.innerMapWrapper}>
                <DistanceAnimation />
              </Box>
            </Box>
          )}
          {answers.map(answer => (
            <Answer
              key={answer.id}
              answer={answer}
              variant={variant}
              selectAnswer={selectAnswer}
            />
          ))}
        </Flex>
      </MotionBox>
    </MotionVStack>
  );
};

export default Answers;
