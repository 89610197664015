import { BoxProps, FlexProps, TextProps } from '@chakra-ui/react';
import { rem } from '@styles/textStyles';
import { AnswersVariant } from '.';

export const container = (variant: AnswersVariant): FlexProps => {
  const base: FlexProps = {
    maxW: rem(1236),
    maxH: 'full',
    width: '100%',
    mx: 'auto',
    gap: rem(8),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: {
      base: 'column',
      md: 'row'
    }
  };
  const distance: FlexProps = {
    flexWrap: 'wrap',
    alignItems: 'end',
    flexDirection: 'row',
    columnGap: {
      base: rem(5),
      md: rem(26)
    }
  };
  return variant === AnswersVariant.DISTANCE
    ? { ...base, ...distance }
    : { ...base };
};

export const itemTitle = (variant: AnswersVariant): BoxProps => {
  const base: BoxProps = {
    fontSize: {
      base: rem(20),
      md: rem(32)
    },
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#fff',
    zIndex: 2,
    fontFamily: 'heading'
  };

  const regular: BoxProps = {
    fontSize: {
      base: rem(20),
      md: rem(40)
    },
    lineHeight: {
      base: rem(20),
      md: rem(40)
    },
    textAlign: {
      base: 'start',
      md: 'center'
    },
    maxWidth: {
      base: rem(158),
      md: 'unset'
    }
  };
  const regularSubtitle: BoxProps = {
    position: 'relative',
    zIndex: 2,
    textAlign: {
      base: 'start',
      md: 'center'
    }
  };
  const time: TextProps = {
    fontSize: rem(71),
    lineHeight: rem(71),
    color: 'green',
    mb: rem(5)
  };
  const distance: TextProps = {
    textTransform: 'uppercase',
    marginTop: '50px',
    textAlign: 'center',
    padding: {
      base: '0px 5px',
      md: '32px 10px'
    },
    minH: {
      base: rem(65),
      md: 'auto'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    border: {
      base: '2px solid #B2B2B2',
      md: '3px solid #B2B2B2'
    },
    borderRadius: rem(10),
    fontSize: {
      base: rem(14),
      md: rem(28)
    },
    lineHeight: {
      base: rem(16),
      md: rem(31)
    },
    fontFamily: 'heading',
    fontWeight: '800',
    color: '#B2B2B2',
    width: {
      base: '100%',
      md: 'auto'
    },
    _hover: {
      background: 'green',
      color: '#fff',
      borderColor: 'green'
    }
  };
  switch (variant) {
    case AnswersVariant.REGULAR:
      return { ...base, ...regular };
    case AnswersVariant.REGULAR_SUBTITLE:
      return { ...base, ...regularSubtitle };
    case AnswersVariant.SMALL:
      return { ...base };
    case AnswersVariant.TIME:
      return { ...time };
    case AnswersVariant.DISTANCE:
      return { ...distance };
    default:
      throw new Error('Variant not found');
  }
};
export const itemImage: BoxProps = {
  transition: '0.3s all ease-in-out',
  margin: 0,
  height: 'full',
  width: 'full',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  _groupHover: {
    transform: 'scale(1.1)',
    filter: 'brightness(1.1)'
  }
};
export const subTitle = (variant: AnswersVariant): TextProps => {
  switch (variant) {
    case AnswersVariant.REGULAR_SUBTITLE:
      return {
        position: 'relative',
        zIndex: 2,
        textAlign: {
          base: 'start',
          md: 'center'
        },
        fontSize: {
          base: rem(14),
          md: rem(22)
        },
        lineHeight: {
          base: rem(16),
          md: rem(22)
        },
        color: '#fff',
        maxWidth: {
          base: rem(193),
          md: rem(330)
        }
      };
    case AnswersVariant.TIME:
      return {
        fontSize: rem(20),
        lineHeight: rem(23),
        textTransform: 'uppercase',
        color: 'rgba(151, 151, 151, 1)',
        textAlign: 'center'
      };
    default:
      throw new Error('Variant not found');
  }
};

export const item = (variant: AnswersVariant): BoxProps => {
  const base: BoxProps = {
    maxWidth: {
      base: '100%',
      md: '25%'
    },
    width: 'full',
    objectFit: 'cover' as const,
    backgroundRepeat: 'no-repeat',
    position: 'relative' as const,
    borderRadius: rem(10),
    transform: 'translate3d(0, 0, 0)',
    overflow: 'hidden',
    cursor: 'pointer',
    role: 'group',
    transition: '0.3s all ease-in-out',
    display: 'flex',
    justifyContent: {
      base: 'flex-start',
      md: 'center'
    },
    alignItems: 'center',
    __css: {
      aspectRatio: {
        base: '4/1.5',
        md: '2/4.73'
      }
    },
    _hover: {
      _after: {
        opacity: '20%'
      }
    }
  };
  const regular: BoxProps = {
    maxWidth: 'unset',
    maxHeight: {
      base: rem(192),
      md: rem(591)
    },
    __css: {
      aspectRatio: {
        base: '2/11',
        md: '2/3.88'
      }
    },
    alignItems: {
      base: 'end',
      md: 'center'
    },
    p: {
      base: rem(17),
      md: rem(10)
    },
    _after: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 1,
      display: 'block',
      content: `' '`,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 1)',
      opacity: {
        base: '30%',
        md: '40%'
      },
      transition: 'opacity 0.2s ease'
    }
  };
  const small: BoxProps = {
    p: rem(24),
    _after: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 1,
      display: 'block',
      content: `' '`,
      width: '100%',
      height: '100%',
      background: {
        base: 'linear-gradient(90deg, rgba(0, 0, 0, 0.6) 31.5%,rgba(0, 0, 0, 0.461538) 58.56%,rgba(0, 0, 0, 0) 93.58%)',
        md: 'rgba(0, 0, 0, 1)'
      },
      opacity: {
        base: '30%',
        md: '40%'
      },
      transition: 'opacity 0.2s ease'
    }
  };
  const regularSubtitle: BoxProps = {
    p: rem(24),
    position: 'relative',
    zIndex: 1,
    maxWidth: 'unset',
    maxHeight: {
      base: rem(192),
      md: rem(591)
    },
    alignItems: {
      base: 'end',
      md: 'center'
    },
    __css: {
      aspectRatio: {
        base: '1/1',
        md: '1/1'
      }
    },
    _after: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 1,
      display: 'block',
      content: `' '`,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 1)',
      opacity: {
        base: '30%',
        md: '40%'
      },
      transition: 'opacity 0.2s ease'
    }
  };
  const time: BoxProps = {
    maxW: {
      base: '100%',
      md: rem(242)
    },
    minH: {
      base: rem(150),
      md: rem(591)
    },
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontFamily: 'heading',
    border: '2px solid rgba(151, 151, 151, 0.2)',
    borderRadius: rem(8),
    transition: '0.2s all ease-in-out',
    _hover: {
      bg: 'green',
      cursor: 'pointer',
      p: {
        color: '#fff'
      }
    }
  };
  const distance: BoxProps = {
    width: {
      base: 'calc(33% - 0.3125rem)',
      md: '100%'
    },
    maxW: rem(272),
    zIndex: 1
  };
  switch (variant) {
    case AnswersVariant.REGULAR:
      return { ...base, ...regular };
    case AnswersVariant.REGULAR_SUBTITLE:
      return { ...base, ...regularSubtitle };
    case AnswersVariant.SMALL:
      return { ...base, ...small };
    case AnswersVariant.TIME:
      return { ...time };
    case AnswersVariant.DISTANCE:
      return { ...distance };

    default:
      throw new Error('Variant not found');
  }
};

export const mapWrapper: BoxProps = {
  h: {
    base: 'full',
    md: 'full'
  },
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '54px',
  justifyContent: 'flex-end',
  w: 'full'
};
export const innerMapWrapper: BoxProps = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  gap: rem(12),
  marginTop: {
    base: '0',
    md: rem(400)
  }
};
