import { forwardRef } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import { breakpoints } from '@styles/theme';
import type { HTMLChakraProps } from '@chakra-ui/system';
import type { Directus_Files } from 'src/queries/directus';

export type DirectusResponsiveImageProps = {
  image: Directus_Files;
  imageMobile?: Directus_Files;
  pictureProps?: HTMLChakraProps<'picture'>;
  noAspect?: boolean;
} & HTMLChakraProps<'img'>;

const DirectusResponsiveImage = forwardRef<
  DirectusResponsiveImageProps,
  'picture'
>(({ image, imageMobile, pictureProps, noAspect = false, ...props }, ref) => {
  const src = image.filename_disk.includes('_next')
    ? image.filename_disk
    : `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${image.filename_disk}`;

  let srcMobile: string;

  if (imageMobile) {
    srcMobile = imageMobile.filename_disk.includes('_next')
      ? imageMobile.filename_disk
      : `${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/${imageMobile.filename_disk}`;
  }

  return (
    <chakra.picture ref={ref} {...pictureProps}>
      {srcMobile && (
        <source srcSet={srcMobile} media={`(max-width: ${breakpoints.md})`} />
      )}
      <chakra.img
        {...props}
        __css={{ aspectRatio: noAspect ? 'unset !important' : 'inherit' }}
        src={src}
        alt={image.description || image.title}
      />
    </chakra.picture>
  );
});

export default DirectusResponsiveImage;
